<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { onMounted, ref } from "vue";
import { useUserStore } from "../../../stores/user";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import useInboundOrders from "../../../hooks/useInboundOrders";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import { shortDateWithTimeTz } from "../../../utils/dateUtils";
import InboundOrderLayout from "../../../layouts/InboundOrderLayout.vue";
import { AgGridVue } from "ag-grid-vue3";
import { useThemeStore } from "@/stores/themeStore";
import { getDefaultColDefs, getDefaultGridOptions } from "@/utils/aggridCommonConfigs";
import { connectToServerApi } from "@/utils/agGridUtils";
import { LicenseManager } from "ag-grid-enterprise";

import { defineEmits } from "vue";
const emit = defineEmits(["inboundOrder"]);

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const { inboundOrder, fetchInboundOrder } = useInboundOrders();

const userStore = useUserStore();
const route = useRoute();
const themeStore = useThemeStore();

let gridApi;
let context = ref(null);

const INBOUND_ORDERS_PALLETS_URL = `/inbound_orders/${route.params.id}/lps?expand=bin`;

const advancedFilterKeys = ["number", "bin.name", "expiry", "lot", "pallet_qty"];

onMounted(async () => {
  await fetchInboundOrder(route.params.id).then(() => {
    setPageTitle(`IN-${inboundOrder.value.id} Pallets`);
  });
});


const onGridReady = (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: INBOUND_ORDERS_PALLETS_URL,
    advancedFilterKeys: advancedFilterKeys,
  }
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const defaultColDef = getDefaultColDefs();

const columnDefs = [
  { headerName: "PLT #", cellDataType: "text", valueGetter: "node.rowIndex + 1", filter: false, sortable: false, floatingFilter: false, width: "50"},
  { field: "number", minWidth: 150, headerName: "LP NUMBER", colId: "number", cellDataType: "text",
    cellRenderer: function (params) {
      if (params.data.number) {
        return `<a href="/lps/${params.data.id}">${params.data.number}</a>`;
      } else {
        return '';
      }
    },
  },
  { field: "bin.name", minWidth: 150, headerName: "LOCATION", colId: "bin.name", cellDataType: "text", width: "300",
    cellRenderer: function (params) {
      if (params.data.bin.name) {
        return `<a href="/bins/${params.data.bin.id}">${params.data.bin.name}</a>`;
      } else {
        return '';
      }
    },
  },
  { field: "product.sku", minWidth: 150, headerName: "SKU", colId: "product.sku", cellDataType: "text", width: "300", sortable: false,
    cellRenderer: function (params) {
      if (params.data.product.sku) {
        return `<a href="/products/${params.data.product_id}">${params.data.product.sku}</a>`;
      } else {
        return '';
      }
    },
  },
  {
    field: "expiry", minWidth: 210, headerName: "EXPIRATION(yyyy-mm-dd)", colId: "expiry", cellDataType: "text",
    valueGetter: (params) => {
      return shortDateWithTimeTz(params.data.expiry);
    }
  },
  { field: "lot", minWidth: 120, headerName: "LOT CODE", colId: "lot", cellDataType: "text", width: "300" },
  { field: 'pallet_qty', minWidth: 120, headerName: "QUANTITY", colId: "pallet_qty", cellDataType: "text", width: "300" },
];

const gridOptions = getDefaultGridOptions(columnDefs);

</script>

<template>
  <InboundOrderLayout :inboundOrder="inboundOrder" v-if="inboundOrder">
    <div class="w-100">
      <div v-if="userStore.user && inboundOrder" class="px-4">
        <div class="w-100 px-2">
          <div class="w-100">
            <OrderHeader
              orderType="inbound"
              :orderId="inboundOrder.id"
              :status="inboundOrder.order_status.short_label"
            />
          </div>

          <v-card class="mt-4 pa-2 w-auto" elevation="0">
            <v-card-title class="text-h5">Pallets</v-card-title>
          </v-card>

          <div class="inbound-pallets-table">
            <AgGridVue 
              style="width: 100%; height: 100%"
              :class="themeStore.agTheme"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :gridOptions="gridOptions"
              @grid-ready="onGridReady"
              :context="context"
            ></AgGridVue>
          </div>
        </div>
      </div>
    </div>
  </InboundOrderLayout>
</template>

<style scoped>
.inbound-pallets-table {
  height: 570px;
  margin-top: 15px;
}
</style>